import React, { useEffect, useRef, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  changePassword,
  sendCodeForgot,
  sendCodeForgotUsername,
  validateCodeResult,
  getUserName,
} from "../services/Login";
import { ForgotValid } from "../validations/ForgotValid";
import { RegisterValid } from "../validations/RegisterValid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ForgotUsername = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [inputMobile, setInputMobile] = useState("");
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [otpFiledDisabled, setOtpFiledDisabled] = useState(true);
  const [forgotFields, setForgotFields] = useState({
    verificationCode: "",
  });
  const [forgotFieldsErr, setForgotFieldsErr] = useState({
    contactValue: "",
    verificationCode: "",
  });
  const [passwordFileds, setPasswordFileds] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordFiledErr, setPasswordFiledErr] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [disableGetCode, setDisableGetCode] = useState(false);
  const [codeText, setCodeText] = useState("Get Code");
  const [username, setUsername] = useState("");
  const [regTimer, setRegTimer] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [automateCountryCode, setAutomateCountryCode] = useState("us");
  const intervalRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("");

  const onCopy = () => {
    setCopySuccess("Copied!");
    setTimeout(() => setCopySuccess(""), 2000); // Reset the message after 2 seconds
  };

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setAutomateCountryCode(
      data.country_code ? data.country_code.toLowerCase() : "us"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const startCountdown = (seconds) => {
    let counter = seconds;
    intervalRef.current = setInterval(() => {
      setRegTimer(counter);
      setTimerText("Resend In");
      setBtnDisabled(true);
      setDisableGetCode(true);
      counter--;
      if (counter < 0) {
        clearInterval(intervalRef.current);
        setBtnDisabled(false);
        setCodeText("Resend Code");
        setTimerText("");
        setDisableGetCode(false);
      }
    }, 1000);
  };

  const handleOnChange = (value, country) => {
    const effectiveValue = value.replace(/[\s\+]/g, "");
    const isEffectivelyEmpty = effectiveValue === country.dialCode;
    setEmailErr("");
    if (isEffectivelyEmpty) {
      setSelectedFiledType("email");
      setMobile("");
      setEmailErr("This field is required");
    } else {
      setSelectedFiledType("mobile");
      setEmailErr("");
    }
    setCodeText("Get Code");
    setMobile(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "verificationCode") {
      if (value.length > 6) {
        return;
      }
      if (value.length === 6) {
        validateCode(value);
      }
    }
    setForgotFields({ ...forgotFields, [name]: value });
    setCodeText("Get Code");
    const checkRegister = ForgotValid(name, value);
    setForgotFieldsErr({ ...forgotFieldsErr, [name]: checkRegister });
    if (name === "contactValue") {
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      if (isMobile) {
        setSelectedFiledType("mobile");
      } else if (isEmail) {
        setSelectedFiledType("email");
      }
    }
  };

  const handleObBlur = (e) => {
    const { name } = e.target;

    if (name === "email" && email === "") {
      setEmailErr("This field is required");
    } else if (name === "mobile" && mobile === "") {
      setMobileErr("This field is required");
    } else if (
      name === "verificationCode" &&
      forgotFields.verificationCode === ""
    ) {
      setForgotFieldsErr((prevErrors) => ({
        ...prevErrors,
        verificationCode: "This field is required",
      }));
    } else if (name === "newPassword" && passwordFileds.newPassword === "") {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        newPassword: "This field is required",
      }));
    } else if (
      name === "confirmPassword" &&
      passwordFileds.confirmPassword === ""
    ) {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "This field is required",
      }));
    } else if (name !== "newPassword" && name !== "confirmPassword") {
      // Clear the confirmPassword error if the user is typing in other fields
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    if (!mobile && !email) {
      setEmailErr("This field is required");
      return false;
    }
    if (selectedFiledType === "email") {
      if (!/^[a-z0-9][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/gi.test(email)) {
        setEmailErr("Please enter a valid email address");
        return false;
      }
    }
    if (selectedFiledType === "mobile" && mobile) {
      const isValidLength = mobile.length >= 7 && mobile.length <= 15;
      if (!isValidLength) {
        setMobileErr("Please enter a valid mobile number");
        return false;
      } else {
        setMobileErr("");
      }
    }
    const otpData = {
      filedType: selectedFiledType,
      contactValue: selectedFiledType === "email" ? email : mobile,
    };
    const result = await sendCodeForgotUsername(otpData);
    if (result.status) {
      toast.success(result.message);
      startCountdown(30);
      setOtpFiledDisabled(false);
    } else {
      toast.error(result.message);
      return;
    }
  };

  const validateCode = async (code) => {
    if (!mobile && !email) {
      setEmailErr("This field is required");
      return false;
    }
    const data = {
      filedType: selectedFiledType,
      currentValue: selectedFiledType === "email" ? email : mobile,
      otp: code,
    };
    const validateCodeRes = await validateCodeResult(data);
    if (validateCodeRes.status) {
      const res = await getUserName(data);
      console.log(res, "res get user");
      setUsername(res.data.username);
      toast.success(validateCodeRes.message);
      setDisableGetCode(true);
      setCodeText("Verified");
      setIsCodeVerified(true);
      clearInterval(intervalRef.current);
      setTimerText("");
      setRegTimer(0);
    } else {
      toast.error(validateCodeRes.message);
      setCodeText("Resend Code");
      setDisableGetCode(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      if (isMobile) {
        setSelectedFiledType("mobile");
        setInputMobile(value);
        setMobile(value);
        setEmailErr("");
        return;
      } else if (isEmail) {
        setSelectedFiledType("email");
      }
      setEmail(value);
      setCodeText("Get Code");
      const emailvalidation = RegisterValid(name, value);
      setEmailErr(emailvalidation);
      setMobileErr("");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login_form p70">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto">
              {isCodeVerified === false ? (
                <div className="bg3 p-3 p-md-4 br20">
                  <h3 className="mb-3">{t("Forgot Username")}</h3>
                  <form className="">
                    <div className="form-group">
                      <label>{t("Email or Mobile")}</label>
                      {selectedFiledType === "mobile" ? (
                        <PhoneInput
                          key={"phoneInput"}
                          country={automateCountryCode} // Default country (you can change it based on user's location)
                          value={inputMobile}
                          onChange={handleOnChange}
                          className=""
                          placeholder="Email or Mobile Number"
                          enableSearch={true}
                          containerClass="baseclass"
                          inputClass="somebaseclass"
                          countryCodeEditable={false}
                          inputProps={{
                            autoFocus: true,
                            name: "mobile",
                          }}
                          disabled={disableGetCode}
                          onBlur={handleObBlur}
                        />
                      ) : (
                        <input
                          key={"regularInput"}
                          className="form-control"
                          autoComplete="off"
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email or Mobile Number"
                          value={email}
                          onChange={handleChangeEmail}
                          onBlur={handleObBlur}
                          disabled={disableGetCode}
                          autoFocus
                          onPaste={(e) => e.preventDefault()}
                        />
                      )}
                      {emailErr && (
                        <span className="text-danger">{t(emailErr)}</span>
                      )}
                      {mobileErr && (
                        <span className="text-danger">{t(mobileErr)}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>{t("Verification Code")}</label>
                      <div className=" position-relative">
                        <input
                          className="form-control"
                          autoComplete="off"
                          type="number"
                          name="verificationCode"
                          id="verificationCode"
                          onChange={handleChange}
                          placeholder="Verification Code"
                          value={forgotFields.verificationCode}
                          disabled={regTimer > 0 ? false : otpFiledDisabled}
                          onKeyPress={handleKeyPress}
                          onBlur={handleObBlur}
                        />
                        <button
                          className="btn position-absolute verification_btn"
                          type="submit"
                          onClick={sendOtp}
                          disabled={btnDisabled}
                        >
                          {t(timerText)}
                          {"  "}
                          {regTimer === 0 ? t(codeText) : t(regTimer)}
                        </button>
                      </div>
                      <span className="text-danger">
                        {t(forgotFieldsErr.verificationCode)}
                      </span>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="bg3 p-3 p-md-4 br20">
                  <h3 className="mb-3">{t("Username")}</h3>
                  <form className="">
                    <div className="form-group">
                      {/* <label>{t("Your Username")}</label> */}
                      <div className="eye_pass position-relative">
                        <input
                          key={"regularInput"}
                          className="form-control"
                          autoComplete="off"
                          value={username}
                        />
                        <CopyToClipboard text={username} onCopy={onCopy}>
                          <span className="password__show position-absolute eye1">
                            {copySuccess ? (
                              <i class="bi bi-clipboard-check-fill"></i>
                            ) : (
                              <i class="bi bi-clipboard"></i>
                            )}
                          </span>
                        </CopyToClipboard>

                        <span className="text-danger">
                          {t(passwordFiledErr.newPassword)}
                        </span>

                        {copySuccess && (
                          <span className="copy-success">{copySuccess}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group log-btn mx-auto">
                        <Link className="btn w100" to={"/login"}>
                          {t("Login Now")}
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotUsername;
