import { myApi } from "./api";
import { runScript } from "../components/constant/Api";

export const PythonScripoAPI = myApi.injectEndpoints({
  endpoints: (builder) => ({
    pythonScript: builder.mutation({
      query: (post) => ({
        url: runScript,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        console.log(response)
        return response.success ? response.trades ?? [] : [];
      },
    }),
  }),
});

export const { usePythonScriptMutation } = PythonScripoAPI;
