import React from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <section className="banner d-flex">
        <div className="container m-auto">
          <div className="row   align-items-center">
            <div className="  col-md-6 order-md-2">
              <div className="banner__image p-4 p-md-0">
                <img className="img-fluid" src="img/bb.png" alt="Head Logo" />
              </div>
            </div>
            <div className="  col-md-6 order-md-1">
              <div className="banner__content">
                <h2 className="title">
                  {t("Buy & Sell Digital Assets In The Dynamic Tech")}
                </h2>
                <p className="fs-20 desc">
                  Dynamic Tech{" "}
                  {t(
                    "Easiest, safest, and fastest way to buy & sell crypto assets"
                  )}
                  .
                </p>
                <Link to="/register" className="btn pl-md-4 pr-md-4 mt-3">
                  <span>{t("Get started now")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="startrader_section p70 bg3">
        <div className="container">
          <div className="row pt-md-3 align-items-center">
            <div className="col-md-5   mb-4">
              <img src="/img/about.png" alt="crypto" className="img-fluid " />
            </div>

            <div className="col-md-7   mb-4">
              <h3 className="hadding  mb-2">{t("About Dynamic Tech")}</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s. Lorem Ipsum is simply dummy text of
                the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s. Lorem Ipsum
                is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s. Lorem Ipsum is simply dummy text of
                the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s. Lorem Ipsum
                is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="startrader_section p70  text-center">
        <div className="container">
          <h3 className="hadding text-center mb-2">
            {t("Trade and shine with Dynamic Tech")}
          </h3>
          <p className=" text-center mb-1">
            We offer you the perfect fusion of security, technology, and
            accessibility to trade. So, join us in the world of trading and
            discover opportunities across hundreds of instruments in five asset
            classes.
          </p>
          <p className=" text-center mb-4">
            {" "}
            Dynamic Tech provides a wide range of instruments, including forex,
            shares, indices, metals, and commodities. We guarantee fast and
            accurate order execution through advanced technology and top-tier
            bank connections. With a 99.6% accuracy rate, we handle a
            significant number of daily forex transactions.
          </p>

          <div className="row pt-md-3">
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="ts_box bg2 br20 p-4">
                <img
                  src="/img/crypto2.png"
                  alt="crypto"
                  className="img-fluid br20"
                />
                <h4 className="">{t("Crypto")}</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="ts_box bg2 br20 p-4">
                <img
                  src="/img/equity2.png"
                  alt="equity"
                  className="img-fluid"
                />
                <h4 className="">{t("Equity")}</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="ts_box bg2 br20 p-4">
                <img src="/img/fx2.png" alt="fx" className="img-fluid" />
                <h4 className="">{t("FX")}</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="startrader_section p70 bg2 text-center">
        <div className="container">
          <h3 className="hadding text-center mb-2">Why Trade with Dynamic Tech?</h3>
          <p className="sub_hadding text-center mb-4">
            Learn to understand the market through the many education tools we
            offer. Sign up and explore them for free.{" "}
          </p>

          <div className="row pt-md-3">
            <div className="col-md-4 col-sm-6 mb-4">
              <i className="bi bi-file-earmark-lock"></i>
              <h5 className="trade_item_subtitle">
                {t("Segregated client accounts")}
              </h5>
              <p>To ensure your funds are used for trading purposes only.</p>
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <i className="bi bi-arrow-left-right"></i>
              <h5 className="trade_item_subtitle">
                {t(
                  "Hassle-free transactions with multiple deposit/withdrawal methods"
                )}
              </h5>
              <p>
                Multiple deposit and withdrawal methods with zero deposit fees
                on most payment methods.
              </p>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 ">
              <i className="bi bi-globe2"></i>
              <h5 className="trade_item_subtitle">
                {t("Fast & stable 24-hour trading environment")}
              </h5>
              <p>
                24-hours steady trading environment, with orders getting
                processed in milliseconds.
              </p>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 mb-md-0">
              <i className="bi bi-hourglass"></i>

              <h5 className="trade_item_subtitle">Ultra-tight Spreads</h5>
              <p>{t("Ultra-tight spreads starting from 0.0 pips")}</p>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 mb-md-0">
              <i className="bi bi-bank"></i>
              <h5 className="trade_item_subtitle">
                {t("Top institutional liquidity access")}
              </h5>
              <p>
                Institutional-level liquidity access, ensuring ultra-tight
                spread.
              </p>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 mb-md-0">
              <i className="bi bi-menu-up"></i>
              <h5 className="trade_item_subtitle">
                {t("Dedicated 24/7 customer service")}
              </h5>
              <p>A 24/7 service for queries, consultation, and feedback.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="faq p70">
        <div className="container">
          <h3 className="hadding text-center mb-2">{t("FAQs")}</h3>
          <p className="sub_hadding text-center mb-4">
            {t("Various educational tools available")}.
            {t("Free access upon sign-up")}.
          </p>
          <div className="row  ">
            <div className="col-md-8 m-auto">
              <div id="accordion">
                <div className="card bg2 br10">
                  <div className="card-header">
                    <a
                      className=""
                      data-bs-toggle="collapse"
                      href="#collapseOne"
                    >
                      Collapsible Group Item #1
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </div>
                  </div>
                </div>
                <div className="card bg2 br10">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseTwo"
                    >
                      Collapsible Group Item #2
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </div>
                  </div>
                </div>
                <div className="card bg2 br10">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseThree"
                    >
                      Collapsible Group Item #3
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </div>
                  </div>
                </div>
                <div className="card bg2 br10">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapse4"
                    >
                      Collapsible Group Item #4
                    </a>
                  </div>
                  <div
                    id="collapse4"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
