import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import Footer from "../widgets/Footer";
import Moment from "moment";
import { useGetTickitQuery } from "../../redux/supportApi";
import toast from "react-hot-toast";
import { addTickit } from "../services/Support";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
  const { data, error, isLoading, refetch } = useGetTickitQuery();
  const [issueType, setIssueType] = useState("");
  const [isInputShow, setIsInputShow] = useState(false);
  const [issue, setIssue] = useState("");
  const [issueError, setIssueError] = useState("");
  const [desc, setDesc] = useState("");
  const [issueTypeError, setIssueTypeError] = useState("");
  const [userUploadImage, setUserUploadImage] = useState("");
  const [message, setMessage] = useState("");
  const [btnnDisable, setBtnnDisable] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [issueImage, setIssueImage] = useState("");
  const [otherSubErr, setOtherSubErr] = useState("");

  useEffect(() => {
    // Check if data is available and not loading
    if (data && !isLoading) {
      setTabledata(data);
    }
  }, [data, isLoading]);

  const handleChange = (event) => {
    const { value } = event.target;
    setIssueType(value);

    if (value === "") {
      setIssueError("Please select the subject");
      return false;
    }

    if (value === "others") {
      setIsInputShow(true);
      setBtnnDisable(false);
    } else {
      setIsInputShow(false);
      setBtnnDisable(false);
    }
    setIssueError("");
    setOtherSubErr("");
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "issue") {
      const issue = value;
      let IssueError = "";
      if (!issue) {
        IssueError = "This field is required";
      } else if (value.replace(/\s+/g, "").length === 0) {
        IssueError = "Please enter a valid subject field";
      } else if (issue.length > 50) {
        IssueError = "Max character limit is 50";
      }
      setOtherSubErr(IssueError);
    }
    setIssue(value);
  };

  const onChangeImage = (e) => {
    e.preventDefault();

    setIssueImage("");
    setUserUploadImage(false);

    const issueImage = e.target.files[0];
    var fileSize = e.target.files[0].size / 1024;
    setIssueImage(e.target.files[0]);
    setUserUploadImage(URL.createObjectURL(issueImage));

    if (issueImage === false) {
      setMessage("Please select an image.");
      // setRespStatus(false);
      return false;
    }
    if (fileSize > 1024) {
      setMessage("Maximum 1 MB Image allowed.");
      return false;
    }
    if (!issueImage.name.match(/\.(jpg|jpeg|png)$/)) {
      setMessage("Please select a valid image (jpg|jpeg|png).");
      // setRespStatus(false);
      return false;
    }

    if (e.target.files.length > 0) {
      // setFileName(e.target.files[0].name);
    }
    setMessage("");
  };

  const helpsbmt = async (event) => {
    event.preventDefault();

    if (issueType === "") {
      setIssueError("Please select the subject");
      return false;
    }
    if (issueType === "others") {
      if (!issue) {
        setOtherSubErr("This filed is required");
        return false;
      }
    }

    const formData = new FormData();
    formData.append("userId", localStorage.getItem("token"));
    if (issueType === "others") {
      formData.append("issue", issue);
    } else {
      formData.append("issue", issueType);
    }
    if (issueImage) {
      var fileSize = issueImage.size / 1024;
      if (fileSize > 1024) {
        setMessage("Maximum 1 MB Image allowed.");
        return false;
      }
    }
    formData.append("issueType", desc);
    formData.append("issueImage", issueImage);
    if (issueType === "others") {
      if (issue === undefined) {
        setOtherSubErr("This field is required");
        return false;
      }
    }
    if (!desc) {
      setIssueTypeError("This field is required");
      return false;
    }
    setBtnnDisable(true);
    setTimeout(() => setBtnnDisable(false), 2000);
    const respData = await addTickit(formData);
    if (respData.status === true) {
      setIssue("");
      setIssueType("");
      setIssueImage("");
      setDesc("");
      setUserUploadImage("");
      setIsInputShow(false);
      toast.dismiss();
      toast.success(t(respData.message));
      refetch();
    } else {
      toast.dismiss();
      toast.error(t(respData.message));
    }
  };

  const showTableHtml = () => {
    const html = [];
    if (tabledata.length > 0) {
      tabledata.map((value, i) => {
        const U_ID = value.id;
        const encodeMe = btoa(U_ID);

        const UserLink1 = `/Chatting/${encodeMe}/${value.seenStatus}`;

        html.push(
          <tr key={value.id}>
            <td>{value.id}</td>
            <td className="capital">{value.issue}</td>
            <td>
              {value.status == 0 && "Pending"}
              {value.status == 1 && "Resolved"}
            </td>
            <td>{Moment(value.createAt).format("lll")}</td>
            {value.updateAt ? (
              <td>{Moment(value.updateAt).format("lll")}</td>
            ) : (
              <td></td>
            )}
            <td
              className={
                value.status == 0 && value.seenStatus == "1"
                  ? "position-relative chat-count"
                  : ""
              }
            >
              <Link className="btn btn_man position-relative" to={UserLink1}>
                {" "}
                {value.status == 0 ? t("Chat") : t("View")}{" "}
                <span
                  className="chat_notification"
                  style={{
                    display: value.seenStatus == "1" ? "block" : "none",
                  }}
                >
                  {value.seenStatus == "1" ? "+1" : ""}
                </span>
              </Link>
            </td>
          </tr>
        );
      });
    } else {
      html.push(
        <tr>
          <td colSpan={6} className="text-center">
            {t("No Records Found")}
          </td>
        </tr>
      );
    }
    return html;
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />

            <div className="col-lg-8 col-xl-9">
              <div className="p-3  p-md-4 bg2 br20 mb-4">
                <h5 className="mb-3">{t("Support")}</h5>
                <form>
                  <div className=" ">
                    <div className="form-group">
                      <label className="fw-500">{t("Subject")}</label>
                      <select
                        className="form-control"
                        name="issueType"
                        id="issueType"
                        onChange={handleChange}
                        value={issueType}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="">{t("Select Subject")}</option>
                        <option value="Exchange">{t("Exchange")}</option>
                        <option value="Login">{t("Login")}</option>
                        <option value="Signup">{t("Signup")}</option>
                        <option value="Kyc">{t("KYC")}</option>
                        <option value="Account">{t("Account")}</option>
                        <option value="others">{t("Others")}</option>
                      </select>
                      <span style={{ color: "red" }}>{t(issueError)}</span>
                    </div>
                    <div className="form-group">
                      {isInputShow === true && (
                        <>
                          <label className="fw-500">{t("Other Subject")}</label>
                          <input
                            className="form-control"
                            name="issue"
                            id="issue"
                            placeholder="Enter Issue"
                            onChange={handleChangeInput}
                            value={issue}
                          />
                          <span style={{ color: "red" }}>{t(otherSubErr)}</span>
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="fw-500"> {t("Description")} </label>
                      <textarea
                        type="text"
                        className="form-control form_control_textarea"
                        placeholder="Enter Issue Type"
                        id="IssueType"
                        value={desc}
                        onChange={(event) => {
                          setDesc(event.target.value);
                          if (!event.target.value) {
                            setIssueTypeError("This field is required");
                          } else {
                            setIssueTypeError("");
                          }
                        }}
                      />
                      <span style={{ color: "red" }}>{t(issueTypeError)}</span>
                    </div>
                    <div className="form-group mb-0">
                      <label className="fw-500">{t("Attachments")}</label>
                      <div className="add_fileinput form-control">
                        <span>{t("Add File")}</span>
                        <input
                          className="profile_input"
                          type="file"
                          name="issueImage"
                          accept=".jpg, .jpeg, .png"
                          onChange={onChangeImage}
                        />
                      </div>
                      <label className="mb-0">
                        <img
                          src={userUploadImage}
                          className="img-fluid22 support-img"
                          alt=""
                        />
                      </label>
                    </div>
                    <span style={{ color: "red" }}>{t(message)}</span>
                    <div className="form-group">
                      <button
                        onClick={helpsbmt}
                        className="btn w100px btn_man "
                        disabled={btnnDisable}
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="p-3  p-md-4 bg2 br20 mb-4">
                <h5 className="mb-3">{t("Ticket History")}</h5>
                <div className="scrool_wallet_box">
                  <table className="table table-striped mb-0 fs14">
                    <thead>
                      <tr>
                        <th>{t("Ticket Id")}</th>
                        <th>{t("Subject")}</th>
                        <th>{t("Status")}</th>
                        <th>{t("Created At")}</th>
                        <th>{t("Last Updated")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>{showTableHtml()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Support;
