import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section className="bg2 footer" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              src="/img/logo.png"
              alt="header-Logo"
              className="logo logo_lite mb-2 "
            />
            <img
              src="/img/logo.png"
              alt="header-Logo"
              className="logo logo_dark mb-2"
            />

            <p>
              {t("Financial technology and service provider")}.
              {t(
                "Specializes in building and growing Stock/FX brokerages and Cryptocurrency Exchanges"
              )}
              .
              {t(
                "Offers comprehensive technical and advisory services for institutional clients"
              )}
              .
              {/* EBSWARE is a financial technology and service provider dedicated
              to building and growing Stock/FX brokerages and Crypto Currency
              Exchanges for institutional clients. As specialists in brokerage
              and crypto currencies trading platforms, EBSWARE offers
              comprehensive technical and advisory services, enabling clients to
              rapidly start their own brokerage business. */}
            </p>
            <ul className="social_icon ">
              <li>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-telegram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-pinterest"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="nav_link">
                  <h4>{t("About")}</h4>
                  <ul className="">
                    <li className="">
                      <a className="" href="#">
                        {t("About Us")}
                      </a>
                    </li>
                    <li className="">
                      <a className="" href="#">
                        {t("Awards")}{" "}
                      </a>
                    </li>

                    <li className="">
                      <a className=" " href="#">
                        {t("FAQs")}
                      </a>
                    </li>

                    <li className="">
                      <a className="" href="#">
                        {t("Contact Us")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nav_link">
                  <h4>{t("Company")}</h4>
                  <ul>
                    <li>
                      <a href="#"> {t("Risk Disclosure")} </a>
                    </li>
                    <li>
                      <a href="#"> {t("Privacy Policy")} </a>
                    </li>
                    <li>
                      <a href="#"> {t("Return Policy")} </a>
                    </li>
                    <li>
                      <a href="#">{t("Customer Agreement")} </a>
                    </li>
                    <li>
                      <a href="#">{t("AML Policy")} </a>
                    </li>{" "}
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nav_link">
                  <h4> {t("Support")} </h4>
                  <ul>
                    <li>
                      <a href="#"> {t("Help Center")} </a>
                    </li>
                    <li>
                      <a href="#">{t("Fees")} </a>
                    </li>
                    <li>
                      <a href="#"> {t("Security")} </a>
                    </li>
                    <li>
                      <a href="#"> {t("Law Enforcement")} </a>
                    </li>
                    <li>
                      <a href="#"> {t("FAQs")} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="copyright p-3 text-center">
        © 2024 EBSWare. All Rights Reserved
      </div> */}
    </section>
  );
};

export default Footer;
