import React, { useState } from "react";
import Modal from "react-modal"; // Optional: Install via npm install react-modal

// Modal Styles (Inline for example)
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    maxWidth: "840px",    
    margin: "auto", 
    padding: "0px", 
    borderRadius: "8px", 
  },
};

// Sample symbols data
const symbolData = [
  {
    symbol: "XAUUSD",
    description: "Gold",
    category: "commodity cfd",
    provider: "OANDA",
  },
  {
    symbol: "EURUSD",
    description: "EUR/USD",
    category: "forex",
    provider: "OANDA",
  },
  {
    symbol: "NVDA",
    description: "NVIDIA CORPORATION",
    category: "stock",
    provider: "NASDAQ",
  },
  {
    symbol: "NQ",
    description: "E-MINI NASDAQ-100 FUTURES",
    category: "futures",
    provider: "CME",
  },
  {
    symbol: "TSLA",
    description: "TESLA, INC.",
    category: "stock",
    provider: "NASDAQ",
  },
  {
    symbol: "AAPL",
    description: "APPLE INC.",
    category: "stock",
    provider: "NASDAQ",
  },
  {
    symbol: "GBPUSD",
    description: "GBP/USD",
    category: "forex",
    provider: "OANDA",
  },
  {
    symbol: "ES",
    description: "E-MINI S&P 500 FUTURES",
    category: "futures",
    provider: "CME",
  },
];

// Categories filter
const categories = ["All", "Stock", "Forex", "Index", "Crypto"];

export default function SymbolModal({ isOpen, onRequestClose }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Filter function based on search term and category
  const filteredSymbols = symbolData.filter((symbol) => {
    return (
      (selectedCategory === "All" ||
        symbol.category
          .toLowerCase()
          .includes(selectedCategory.toLowerCase())) &&
      (symbol.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        symbol.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false} // Add this if you're not using accessibility features
    >
      <div className="modal-header p-3 mb-0 color_new">
        <h5 className="mb-0">Add symbol</h5>
        <button className="close-btn" onClick={onRequestClose}>
        <i class="bi bi-x-lg"></i>
        </button>
      </div>

      <div className="d-flex search_box align-items-center p-3">
        <i className="bi bi-search mr-3"></i>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
 
      <div className="p-3 color_new">
      <div className="position_tab_in mb-4 mt-2">
        {categories.map((category) => (
          <a
            key={category}
            className={`filter-btn ${
              selectedCategory === category ? "active" : ""
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </a>
        ))}
      </div>
      <div className="popop_table_scrool">
      <table className="symbol-table table table_border add_popop_table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Description</th>
            <th>Category</th>
            <th>Provider</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredSymbols.map((symbol, index) => (
            <tr key={index}>
              <td>{symbol.symbol}</td>
              <td>{symbol.description}</td>
              <td>{symbol.category}</td>
              <td>{symbol.provider}</td>
              <td><i className="bi bi-plus-lg cursor-pointer"></i></td> 
            </tr>
          ))}
        </tbody>
      </table>
</div>
</div>
      <div className="modal-footer p-3 text-center color_new">
        <p className="mb-0">Shift + Click or Shift + Enter to add symbol and close dialog</p>
      </div>
    </Modal>
  );
}
