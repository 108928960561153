import React, { useState } from "react";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import { useTranslation } from "react-i18next";
import {
  useAccountDetailsMutation,
  useGetAccountDetailsQuery,
} from "../../redux/accountApi";
import toast from "react-hot-toast";

const Profile = () => {
  const { t } = useTranslation();
  const [formError, setFormError] = useState({
    bank: {},
    crypto: {},
  });
  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    accountNumber: "",
    bankName: "",
    cryptoWallet: "",
  });

  const { data: userDetails, isLoading, error } = useGetAccountDetailsQuery();
  const [addAccountDetails, { isLoading: isSubmitting }] =
    useAccountDetailsMutation();

  // Load existing data into formData state
  React.useEffect(() => {
    if (userDetails) {
      setFormData({
        firstName: userDetails.first_name || "",
        surname: userDetails.surname || "",
        accountNumber: userDetails.account_number || "",
        bankName: userDetails.bank_name || "",
        cryptoWallet: userDetails.crypto_wallet || "",
      });
    }
  }, [userDetails]);

  const validateField = (name, value, formType) => {
    if (!value.trim()) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [formType]: {
          ...prevErrors[formType],
          [name]: t("This field is required"),
        },
      }));
    } else {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [formType]: {
          ...prevErrors[formType],
          [name]: "",
        },
      }));
    }
  };

  const handleInputChange = (e, formType) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value, formType);
  };

  const handleSubmit = async (event, formType) => {
    event.preventDefault();
    const isValid =
      formType === "bank"
        ? formData.firstName &&
          formData.surname &&
          formData.accountNumber &&
          formData.bankName
        : formData.cryptoWallet;

    if (!isValid) {
      toast.error(t("Please fill out all required fields."));
      return;
    }

    const payload =
      formType === "bank"
        ? {
            type: "bank",
            firstName: formData.firstName,
            surname: formData.surname,
            accountNumber: formData.accountNumber,
            bankName: formData.bankName,
          }
        : {
            type: "crypto",
            cryptoWallet: formData.cryptoWallet,
          };

    try {
      const response = await addAccountDetails(payload).unwrap();
      toast.success(t("Details submitted successfully!"));
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    }
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              {isLoading && <p>{t("Loading...")}</p>}
              {error && (
                <p className="text-danger">
                  {t("Failed to load user details")}
                </p>
              )}

              <div className="p-3 bg2 br20 mb-4">
                <h5 className="mb-2">{t("Banking Details")}</h5>
                <form onSubmit={(e) => handleSubmit(e, "bank")} noValidate>
                  <input type="hidden" name="type" value="bank" />
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="firstName">{t("First Name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter first name")}
                        required
                      />
                      {formError.bank.firstName && (
                        <p className="text-danger mt-1">
                          {formError.bank.firstName}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="surname">{t("Surname")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="surname"
                        name="surname"
                        value={formData.surname}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter surname")}
                        required
                      />
                      {formError.bank.surname && (
                        <p className="text-danger mt-1">
                          {formError.bank.surname}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label htmlFor="accountNumber">
                        {t("Account Number")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="accountNumber"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter account number")}
                        required
                      />
                      {formError.bank.accountNumber && (
                        <p className="text-danger mt-1">
                          {formError.bank.accountNumber}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="bankName">{t("Bank Name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="bankName"
                        name="bankName"
                        value={formData.bankName}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter bank name")}
                        required
                      />
                      {formError.bank.bankName && (
                        <p className="text-danger mt-1">
                          {formError.bank.bankName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("Submitting...")
                        : t("Submit Banking Details")}
                    </button>
                  </div>
                </form>
              </div>

              <div className="p-3 bg2 br20 mb-4">
                <h5 className="mb-2">{t("Crypto Wallet Address")}</h5>
                <form onSubmit={(e) => handleSubmit(e, "crypto")} noValidate>
                  <input type="hidden" name="type" value="crypto" />
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="cryptoWallet">
                        {t("Wallet Address")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cryptoWallet"
                        name="cryptoWallet"
                        value={formData.cryptoWallet}
                        onChange={(e) => handleInputChange(e, "crypto")}
                        placeholder={t("Enter crypto wallet address")}
                        required
                      />
                      {formError.crypto.cryptoWallet && (
                        <p className="text-danger mt-1">
                          {formError.crypto.cryptoWallet}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("Submitting...")
                        : t("Submit Wallet Address")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
