import React, { useState } from "react";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import "./TradingTerminal.css";
import SymbolModal from "../partials/TradingTerminalModel";
import TradingViewTerminal from "../partials/TradingViewTerminal";

const TradingTerminal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [theme, setTheme] = useState("dark");

  return (
    <div>
      <ExchangeNavBar />
      <div className="new_dashbord">
        <div className="dash-grid">
          <div className="dash-grid-l">
            <div className="ne_box bg2 border_box ">
              <TradingViewTerminal theme={theme} />
            </div>
            <div className="ne_box border_box p-2  bg2 trading_table_section">
              <div className="d-flex align-items-center">
                <h6 className="mb-2 mr-3">Account Manager</h6>
                <span className=" mr-auto icon_h_b_btn">Trade</span>

                <span className="icon_hover mr-2">
                  <i className="bi bi-dash-lg"></i>
                </span>
                <span className="icon_hover">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 28"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="currentColor"
                      d="M19.32 6H8.68A2.68 2.68 0 0 0 6 8.68V11h1V8.68C7 7.75 7.75 7 8.68 7h10.64c.93 0 1.68.75 1.68 1.68V11h1V8.68C22 7.2 20.8 6 19.32 6ZM7 19.32c0 .93.75 1.68 1.68 1.68h10.64c.93 0 1.68-.75 1.68-1.68V17h1v2.32C22 20.8 20.8 22 19.32 22H8.68A2.68 2.68 0 0 1 6 19.32V17h1v2.32Z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="table_hadding flex-wrap mb-2">
                <h6 className="h6_head drag-handle">Orders</h6>
                <div className="close-resize">
                  <i className="bi bi-x-lg" />
                </div>
              </div>

              <div className="h-100">
                <div className="position_tab d-md-flex mb-1">
                  <div className=" d-flex mb-2 mb-md-0">
                    <a href="#!" className="active">
                      Positions 6
                    </a>
                    <a href="#!" className="">
                      Orders
                    </a>
                    <a href="#!" className="">
                      Account Summary
                    </a>
                    <a href="#!" className="">
                      Notification Logs
                    </a>
                  </div>
                  <div className="total_profit ml-auto pt-1">
                    <h6 className="mb-0 mr-md-2">
                      Total P &amp; L:{" "}
                      <span style={{ color: "rgb(242, 51, 69)" }}>
                        $-320.03086
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="trading_table mt-3">
                  <table className="table mb-0 ">
                    <thead>
                      <tr>
                        <th className="f-left ng-binding">Pair</th>
                        <th className="f-left ng-binding">Direction</th>
                        <th className="f-left ng-binding">Volume, lots</th>
                        <th className="f-left ng-binding">Entry point</th>
                        <th className="f-left ng-binding">Price change</th>
                        <th className="f-left ng-binding">Take Profit</th>
                        <th className="f-left ng-binding">Stop Loss</th>
                        <th className="f-left ng-binding">Commission</th>
                        <th className="f-left ng-binding">Open Time</th>
                        <th className="f-left ng-binding">Gross Profit</th>
                        <th className="f-left ng-binding">P &amp; L</th>
                        <th className="f-center cancels">
                          <span className="ng-binding p-0">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ cursor: "pointer" }}>
                        <td>BTC/USDT</td>
                        <td>
                          <span
                            className="ng-binding ng-scope"
                            style={{ color: "rgb(0, 188, 139)" }}
                          >
                            BUY
                          </span>
                        </td>
                        <td>0.11</td>
                        <td>65,333.09</td>
                        <td>64,290.01</td>
                        <td>689000</td>
                        <td>-</td>
                        <td>3.59332</td>
                        <td>Sep 27, 2024 12:36:38 PM</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>
                          $-114.5199
                        </td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>
                          $-118.11322
                        </td>
                        <td>
                          <span
                            className="mr-3"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-pencil-square" />
                          </span>
                          <span
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-x-circle" />
                          </span>
                        </td>
                      </tr>
                      <tr style={{ cursor: "pointer" }}>
                        <td>BTC/USDT</td>
                        <td>
                          <span
                            className="ng-binding ng-scope"
                            style={{ color: "rgb(0, 188, 139)" }}
                          >
                            BUY
                          </span>
                        </td>
                        <td>0.002</td>
                        <td>65,369.13</td>
                        <td>64,290.01</td>
                        <td>68000</td>
                        <td>-</td>
                        <td>0.06537</td>
                        <td>Sep 27, 2024 12:35:12 PM</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-2.15426</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-2.21963</td>
                        <td>
                          <span
                            className="mr-3"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-pencil-square" />
                          </span>
                          <span
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-x-circle" />
                          </span>
                        </td>
                      </tr>
                      <tr style={{ cursor: "pointer" }}>
                        <td>BTC/USDT</td>
                        <td>
                          <span
                            className="ng-binding ng-scope"
                            style={{ color: "rgb(0, 188, 139)" }}
                          >
                            BUY
                          </span>
                        </td>
                        <td>0.1</td>
                        <td>65,371.99</td>
                        <td>64,290.01</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3.2686</td>
                        <td>Sep 27, 2024 12:31:05 PM</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-107.999</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>
                          $-111.2676
                        </td>
                        <td>
                          <span
                            className="mr-3"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-pencil-square" />
                          </span>
                          <span
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-x-circle" />
                          </span>
                        </td>
                      </tr>
                      <tr style={{ cursor: "pointer" }}>
                        <td>BTC/USDT</td>
                        <td>
                          <span
                            className="ng-binding ng-scope"
                            style={{ color: "rgb(0, 188, 139)" }}
                          >
                            BUY
                          </span>
                        </td>
                        <td>0.1</td>
                        <td>65,381.63</td>
                        <td>64,290.01</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3.26908</td>
                        <td>Sep 27, 2024 11:34:43 AM</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-108.963</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>
                          $-112.23208
                        </td>
                        <td>
                          <span
                            className="mr-3"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-pencil-square" />
                          </span>
                          <span
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-x-circle" />
                          </span>
                        </td>
                      </tr>
                      <tr style={{ cursor: "pointer" }}>
                        <td>MATIC/USDT</td>
                        <td>
                          <span
                            className="ng-binding ng-scope"
                            style={{ color: "rgb(0, 188, 139)" }}
                          >
                            BUY
                          </span>
                        </td>
                        <td>1</td>
                        <td>0.3796</td>
                        <td>0.3796</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.00019</td>
                        <td>Sep 27, 2024 11:33:55 AM</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-0.0002</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-0.00039</td>
                        <td>
                          <span
                            className="mr-3"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-pencil-square" />
                          </span>
                          <span
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-x-circle" />
                          </span>
                        </td>
                      </tr>
                      <tr style={{ cursor: "pointer" }}>
                        <td>MATIC/USDT</td>
                        <td>
                          <span
                            className="ng-binding ng-scope"
                            style={{ color: "rgb(0, 188, 139)" }}
                          >
                            BUY
                          </span>
                        </td>
                        <td>1</td>
                        <td>0.3796</td>
                        <td>0.3796</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.00019</td>
                        <td>Sep 27, 2024 11:28:23 AM</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-0.0002</td>
                        <td style={{ color: "rgb(242, 51, 69)" }}>$-0.00039</td>
                        <td>
                          <span
                            className="mr-3"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-pencil-square" />
                          </span>
                          <span
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-x-circle" />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="dash-grid-r">
            <div className="dash-grid-two">
              <div className="ne_box h_100 p-3 br10 bg2 border_box">
                <div className="d-flex mb-2">
                  <h6 className="text-left drag-handle mr-auto">Trading</h6>
                  <i className="bi bi-x-lg cursor-pointer" />
                </div>

                <ul className="exchange_tab2  drag-handle br10  d-flex mb-3 position-relative">
                  <span className="bs_center">0.01</span>
                  <li className="drag-cancel ">
                    <span className="d-block fw600 mb-1">Sell</span>
                    63,802.01
                  </li>
                  <li className="drag-cancel my_color_buy ">
                    <span className="d-block fw600 mb-1">Buy</span>
                    63,802
                  </li>
                </ul>
                <div className="position_tab position_tab_center d-md-flex mb-3">
                  <a href="#!" className="active">
                    {" "}
                    Market
                  </a>
                  <a href="#!" className="">
                    Limit
                  </a>
                  <a href="#!" className="">
                    Stop
                  </a>
                </div>
                <div className="buy-btc-outer buy-sell-outer border1">
                  <form
                    name="buyForm"
                    autoComplete="off"
                    className="ng-pristine ng-invalid ng-invalid-required"
                  >
                    <div className="orderforms-inputs">
                      <div className="field f-cb mb-2">
                        <div className="iptwrap leftBig" />
                      </div>
                      <div className="field f-cb mb-2">
                        <label className="ng-binding d-flex">
                          Amount:
                          <span className="f-fr ng-binding ml-auto">
                            USD : 49434.59
                          </span>
                        </label>
                        <div className="iptwrap">
                          <input
                            type="number"
                            step="any"
                            name="buyAmount"
                            id="buyAmount"
                            aria-invalid="true"
                            defaultValue=""
                          />
                          <span className="text-danger mb-2" />
                        </div>
                      </div>
                      <div className="row  pt-3  mb-3 align-items-end">
                        <div className="col-5 pr-0">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input check_box"
                              type="checkbox"
                            />
                            <span className="checkmark" />
                            <label
                              className="form-check-label pl-2"
                              htmlFor="flexCheckChecked"
                            >
                              Take Profit
                            </label>
                          </div>
                          <input
                            type="text"
                            placeholder={0}
                            name="takeProfit"
                            disabled=""
                            defaultValue=""
                          />
                        </div>
                        <div className="col-2 pl-2">
                          <label className="mb-2">Price</label>
                        </div>
                        <div className="col-5 pl-0">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input check_box"
                              type="checkbox"
                            />
                            <span className="checkmark" />
                            <label
                              className="form-check-label pl-2"
                              htmlFor="flexCheckChecked"
                            >
                              Stop Loss
                            </label>
                          </div>
                          <input
                            className=""
                            type="text"
                            placeholder={0}
                            name="stopPriceBuy"
                            disabled=""
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="field percent f-cb">
                        <div className="d-flex field4 mb-2">
                          <span className="col ">25%</span>
                          <span className="col ">50%</span>
                          <span className="col ">75%</span>
                          <span className="col ">100%</span>
                        </div>
                      </div>
                      <div className="field f-cb mb-2">
                        <label className="ng-binding">Total: </label>
                        <div className="iptwrap">
                          <input
                            type="text"
                            step="any"
                            id="total_buy"
                            name="buy_order_total"
                            disabled=""
                            className="ng-pristine ng-untouched ng-valid ng-empty"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="ng-binding" style={{ fontSize: 10 }}>
                          Trade Fee : 0.05<span>%</span>
                        </p>
                        <p className="ng-binding" style={{ fontSize: 10 }}>
                          Net Amount : 0
                        </p>
                      </div>
                      <div className="orderforms-inputs">
                        <button
                          className="btn w100  my_color_buy"
                          type="submit"
                          disabled=""
                        >
                          <div>
                            <span>Buy</span>

                            <span className="btn_small d-block">
                              {" "}
                              BTCUSDT MKT
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="dash-grid-two">
              <div className="ne_box bg2 h_100 border_box d_new_right">
                <div className="panel-heading tab-box p-2">
                  <div className="d-flex mb-2">
                    <h6 className="text-left drag-handle mr-auto">Watchlist</h6>
                    <i
                      className="bi bi-plus-lg cursor-pointer"
                      onClick={() => setIsModalOpen(true)}
                    />
                  </div>
                </div>

                <table className="table table-hover currency_table2">
                  <thead>
                    <tr>
                      <th className="">Symbol</th>
                      <th className="text-right">Last</th>
                      <th className="text-right">Best BID</th>
                      <th className="text-right">Chg</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className="">
                      <td className="border_b_none">
                        {" "}
                        <div className="ttu mt-3 mb-1 op07">Stocks</div>
                      </td>
                    </tr>
                    <tr className="ng-scope active">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                    <tr className="ng-scope ">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                    <tr className="ng-scope ">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>

                    <tr className="">
                      <td className="border_b_none">
                        {" "}
                        <div className="ttu mt-3 mb-1 op07">Forex</div>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                    <tr className="ng-scope ">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                    <tr className="ng-scope ">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>

                    <tr className="">
                      <td className="border_b_none">
                        {" "}
                        <div className="ttu mt-3 mb-1 op07">Crypto</div>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                    <tr className="ng-scope ">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                    <tr className="ng-scope ">
                      <td className="">
                        <img
                          className="currency_icon"
                          src="img/currency-1659701693850.png"
                        />
                        <span className="fw600">BNB</span>
                      </td>
                      <td className="text-right">226.00</td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <td className="text-right">
                        <span className="text-success">1.00</span>
                      </td>
                      <i className="bi bi-trash3 cursor-pointer"></i>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SymbolModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default TradingTerminal;
