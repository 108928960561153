import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "./helpers/en/translation.json";
import arTranslation from "./helpers/ar/translation.json";
import cnTranslation from "./helpers/cn/translation.json";
import perTranslation from "./helpers/per/translation.json";
import rusTranslation from "./helpers/rus/translation.json";
import hnTranslation from "./helpers/hn/translation.json";

i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem("lang"),
    lng: localStorage.getItem("lang"),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslation,
      },
      cn: {
        translation: cnTranslation,
      },
      hn: {
        translation: hnTranslation,
      },
      // ar: {
      //   translation: arTranslation,
      // },
      // per: {
      //   translation: perTranslation,
      // },
      // rus: {
      //   translation: rusTranslation,
      // },
    },
  });

export default i18n;
