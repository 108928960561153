import React, { useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemType = "ITEM";

const DraggableItem = ({
  id,
  exchangeInstrumentId,
  text,
  index,
  moveItem,
  active,
  onClick = () => {},
  onVisibilityChange = () => {},
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (item.index === index) return;
      moveItem(item.index, index);
      item.index = index; // Ensure to update the index in the item object
    },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        onVisibilityChange(exchangeInstrumentId, entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [id, onVisibilityChange]);

  drag(drop(ref));

  return (
    <tr
      className={active ? "ng-scope active" : "ng-scope"}
      ref={ref}
      style={{
        opacity: isDragging ? 0 : 1,
        cursor: "grab",
      }}
      onClick={onClick}
    >
      {text}
    </tr>
  );
};

export default DraggableItem;
