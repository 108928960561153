import React, { useCallback, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import Sidebar from "../widgets/Sidebar";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Editor from "@monaco-editor/react";
import { usePythonScriptMutation } from "../../redux/scriptApi";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const UploadScript = () => {
  const { t } = useTranslation();
  const [runScript] = usePythonScriptMutation();
  const [fileContent, setFileContent] = useState("");
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [executionResult, setExecutionResult] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Callback for handling dropped files
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setFileContent(reader.result); // Set the content of the file
        setIsEditorVisible(true); // Show the editor
      };
      reader.readAsText(file); // Read file content as text
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".py", // Accept only .py files
    multiple: false, // Single file upload
  });

  // Submit script to the backend for execution
  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable button during submission
    try {
      const scriptObj = {
        scriptContent: fileContent,
        balance: 100000,
      };
      const res = await runScript(scriptObj);
      setExecutionResult(res.data); // Display the output/logs from backend
    } catch (error) {
      console.error("Error running script:", error);
      setExecutionResult({
        error: "Failed to execute script. Please try again.",
      });
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3 bg2 br20 mb-4 h100">
                <h5 className="mb-2">{t("For You")}</h5>
             
                        {/* Show Dropzone if editor is not visible */}
                        {!isEditorVisible ? (
                          <div
                            {...getRootProps()}
                            className={`dropzone p-4 border border-dashed rounded ${
                              isDragActive ? "active" : ""
                            }`}
                            style={{
                              border: "2px dashed #01d59b",
                              borderRadius: "10px",
                              padding: "20px",
                              textAlign: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop the .py file here...</p>
                            ) : (
                              <p>
                                Drag & drop a .py file here, or click to select
                                one
                              </p>
                            )}
                          </div>
                        ) : (
                          // Show Monaco Editor if file is uploaded
                          <Editor
                            height="500px"
                            defaultLanguage="python"
                            theme="vs-dark"
                            value={fileContent}
                            onChange={(newValue) => setFileContent(newValue)}
                            options={{
                              autoClosingBrackets: "always",
                              suggestOnTriggerCharacters: true,
                            }}
                          />
                        )}
                         {/* Display Submit button and script output */}
                    {isEditorVisible && (
                      <div className="mt-3">
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Running Script..." : "Submit Script"}
                        </button>
                      </div>
                    )}

{executionResult && (
                      <div className="mt-4">
                        <h5>Execution Result:</h5>
                        {executionResult.error ? (
                          <p className="text-danger">{executionResult.error}</p>
                        ) : (
                          <div className="row">
                            {executionResult.map((trade, index) => (
                              <div
                                key={index}
                                className="col-md-6 col-lg-4 mb-4"
                              >
                                <div
                                  className="card shadow-sm"
                                  style={{
                                    borderRadius: "10px",
                                    borderLeft:
                                      trade.action === "BUY"
                                        ? "4px solid #28a745"
                                        : "4px solid #dc3545",
                                  }}
                                >
                                  <div
                                    className="card-header d-flex align-items-center"
                                    style={{
                                      backgroundColor:
                                        trade.action === "BUY"
                                          ? "#28a745"
                                          : "#dc3545",
                                      color: "#fff",
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {trade.action} {trade.order_type} for{" "}
                                    {trade.symbol} on {trade.date}
                                  </div>
                                  <div className="card-body">
                                    <p className="mb-1">
                                      <strong>Symbol:</strong> {trade.symbol}
                                    </p>
                                    <p className="mb-1">
                                      <strong>Price:</strong> $
                                      {trade.price.toFixed(2)}
                                    </p>
                                    <p className="mb-1">
                                      <strong>Quantity:</strong>{" "}
                                      {trade.quantity}
                                    </p>
                                    <p className="mb-1">
                                      <strong>Balance:</strong> $
                                      {trade.balance.toFixed(2)}
                                    </p>
                                    <p className="mb-1">
                                      <strong>Order Type:</strong>{" "}
                                      {trade.order_type}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                      </div>
                    </div>

                   

                    {/* Display Execution Results */}
                  
             
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UploadScript;
